
    <template>
      <section class="content element-doc">
        <h2>Tabs 标签页</h2>
<p>分隔内容上有关联但属于不同类别的数据集合。</p>
<h3>基础用法</h3>
<p>基础的、简洁的标签页。</p>
<demo-block>
        <div><p>Tabs 组件提供了选项卡功能，默认选中第一个标签页，你也可以通过 <code>value</code> 属性来指定当前选中的标签页。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-tabs v-model=&quot;activeName&quot; @tab-click=&quot;handleClick&quot;&gt;
    &lt;el-tab-pane label=&quot;用户管理&quot; name=&quot;first&quot;&gt;用户管理&lt;/el-tab-pane&gt;
    &lt;el-tab-pane label=&quot;配置管理&quot; name=&quot;second&quot;&gt;配置管理&lt;/el-tab-pane&gt;
    &lt;el-tab-pane label=&quot;角色管理&quot; name=&quot;third&quot;&gt;角色管理&lt;/el-tab-pane&gt;
    &lt;el-tab-pane label=&quot;定时任务补偿&quot; name=&quot;fourth&quot;&gt;定时任务补偿&lt;/el-tab-pane&gt;
  &lt;/el-tabs&gt;
&lt;/template&gt;
&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const activeName = ref('second')
      const handleClick = (tab, event) =&gt; {
        console.log(tab, event)
      }
      return { activeName, handleClick }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>选项卡样式</h3>
<p>选项卡样式的标签页。</p>
<demo-block>
        <div><p>只需要设置 <code>type</code> 属性为 <code>card</code> 就可以使选项卡改变为标签风格。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-tabs v-model=&quot;activeName&quot; type=&quot;card&quot; @tab-click=&quot;handleClick&quot;&gt;
    &lt;el-tab-pane label=&quot;用户管理&quot; name=&quot;first&quot;&gt;用户管理&lt;/el-tab-pane&gt;
    &lt;el-tab-pane label=&quot;配置管理&quot; name=&quot;second&quot;&gt;配置管理&lt;/el-tab-pane&gt;
    &lt;el-tab-pane label=&quot;角色管理&quot; name=&quot;third&quot;&gt;角色管理&lt;/el-tab-pane&gt;
    &lt;el-tab-pane label=&quot;定时任务补偿&quot; name=&quot;fourth&quot;&gt;定时任务补偿&lt;/el-tab-pane&gt;
  &lt;/el-tabs&gt;
&lt;/template&gt;
&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const activeName = ref('first')
      const handleClick = (tab, event) =&gt; {
        console.log(tab, event)
      }
      return { activeName, handleClick }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>卡片化</h3>
<p>卡片化的标签页。</p>
<demo-block>
        <div><p>将<code>type</code>设置为<code>border-card</code>。</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tabs type=&quot;border-card&quot;&gt;
  &lt;el-tab-pane label=&quot;用户管理&quot;&gt;用户管理&lt;/el-tab-pane&gt;
  &lt;el-tab-pane label=&quot;配置管理&quot;&gt;配置管理&lt;/el-tab-pane&gt;
  &lt;el-tab-pane label=&quot;角色管理&quot;&gt;角色管理&lt;/el-tab-pane&gt;
  &lt;el-tab-pane label=&quot;定时任务补偿&quot;&gt;定时任务补偿&lt;/el-tab-pane&gt;
&lt;/el-tabs&gt;
</code></pre></template></demo-block><h3>位置</h3>
<p>可以通过 <code>tab-position</code> 设置标签的位置</p>
<demo-block>
        <div><p>标签一共有四个方向的设置 <code>tabPosition=&quot;left|right|top|bottom&quot;</code></p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-radio-group v-model=&quot;tabPosition&quot; style=&quot;margin-bottom: 30px;&quot;&gt;
    &lt;el-radio-button label=&quot;top&quot;&gt;top&lt;/el-radio-button&gt;
    &lt;el-radio-button label=&quot;right&quot;&gt;right&lt;/el-radio-button&gt;
    &lt;el-radio-button label=&quot;bottom&quot;&gt;bottom&lt;/el-radio-button&gt;
    &lt;el-radio-button label=&quot;left&quot;&gt;left&lt;/el-radio-button&gt;
  &lt;/el-radio-group&gt;

  &lt;el-tabs :tab-position=&quot;tabPosition&quot; style=&quot;height: 200px;&quot;&gt;
    &lt;el-tab-pane label=&quot;用户管理&quot;&gt;用户管理&lt;/el-tab-pane&gt;
    &lt;el-tab-pane label=&quot;配置管理&quot;&gt;配置管理&lt;/el-tab-pane&gt;
    &lt;el-tab-pane label=&quot;角色管理&quot;&gt;角色管理&lt;/el-tab-pane&gt;
    &lt;el-tab-pane label=&quot;定时任务补偿&quot;&gt;定时任务补偿&lt;/el-tab-pane&gt;
  &lt;/el-tabs&gt;
&lt;/template&gt;
&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const tabPosition = ref('left')
      return { tabPosition }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>自定义标签页</h3>
<p>可以通过具名 <code>slot</code> 来实现自定义标签页的内容</p>
<demo-block>
        
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tabs type=&quot;border-card&quot;&gt;
  &lt;el-tab-pane&gt;
    &lt;template v-slot:label&gt;&lt;i class=&quot;el-icon-date&quot;&gt;&lt;/i&gt; 我的行程&lt;/template&gt;
    我的行程
  &lt;/el-tab-pane&gt;
  &lt;el-tab-pane label=&quot;消息中心&quot;&gt;消息中心&lt;/el-tab-pane&gt;
  &lt;el-tab-pane label=&quot;角色管理&quot;&gt;角色管理&lt;/el-tab-pane&gt;
  &lt;el-tab-pane label=&quot;定时任务补偿&quot;&gt;定时任务补偿&lt;/el-tab-pane&gt;
&lt;/el-tabs&gt;
</code></pre></template></demo-block><h3>动态增减标签页</h3>
<p>增减标签页按钮只能在选项卡样式的标签页下使用</p>
<demo-block>
        
        <template #source><element-demo5 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tabs
  v-model=&quot;editableTabsValue&quot;
  type=&quot;card&quot;
  editable
  @edit=&quot;handleTabsEdit&quot;
&gt;
  &lt;el-tab-pane
    :key=&quot;item.name&quot;
    v-for=&quot;(item, index) in editableTabs&quot;
    :label=&quot;item.title&quot;
    :name=&quot;item.name&quot;
  &gt;
    {{item.content}}
  &lt;/el-tab-pane&gt;
&lt;/el-tabs&gt;
&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      const state = reactive({
        editableTabsValue: '2',
        editableTabs: [
          {
            title: 'Tab 1',
            name: '1',
            content: 'Tab 1 content'
          },
          {
            title: 'Tab 2',
            name: '2',
            content: 'Tab 2 content'
          }
        ],
        tabIndex: 2
      })
      const handleTabsEdit = (targetName, action) =&gt; {
        if (action === 'add') {
          let newTabName = ++state.tabIndex + ''
          state.editableTabs.push({
            title: 'New Tab',
            name: newTabName,
            content: 'New Tab content'
          })
          state.editableTabsValue = newTabName
        }
        if (action === 'remove') {
          let tabs = state.editableTabs
          let activeName = state.editableTabsValue
          if (activeName === targetName) {
            tabs.forEach((tab, index) =&gt; {
              if (tab.name === targetName) {
                let nextTab = tabs[index + 1] || tabs[index - 1]
                if (nextTab) {
                  activeName = nextTab.name
                }
              }
            })
          }

          state.editableTabsValue = activeName
          state.editableTabs = tabs.filter((tab) =&gt; tab.name !== targetName)
        }
      }

      return { ...toRefs(state), handleTabsEdit }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>自定义增加标签页触发器</h3>
<demo-block>
        
        <template #source><element-demo6 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div style=&quot;margin-bottom: 20px;&quot;&gt;
  &lt;el-button size=&quot;small&quot; @click=&quot;addTab(editableTabsValue)&quot;&gt;
    add tab
  &lt;/el-button&gt;
&lt;/div&gt;
&lt;el-tabs
  v-model=&quot;editableTabsValue&quot;
  type=&quot;card&quot;
  closable
  @tab-remove=&quot;removeTab&quot;
&gt;
  &lt;el-tab-pane
    v-for=&quot;(item, index) in editableTabs&quot;
    :key=&quot;item.name&quot;
    :label=&quot;item.title&quot;
    :name=&quot;item.name&quot;
  &gt;
    {{item.content}}
  &lt;/el-tab-pane&gt;
&lt;/el-tabs&gt;
&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      const state = reactive({
        editableTabsValue: '2',
        editableTabs: [
          {
            title: 'Tab 1',
            name: '1',
            content: 'Tab 1 content'
          },
          {
            title: 'Tab 2',
            name: '2',
            content: 'Tab 2 content'
          }
        ],
        tabIndex: 2
      })

      const addTab = (targetName) =&gt; {
        let newTabName = ++state.tabIndex + ''
        state.editableTabs.push({
          title: 'New Tab',
          name: newTabName,
          content: 'New Tab content'
        })
        state.editableTabsValue = newTabName
      }

      const removeTab = (targetName) =&gt; {
        let tabs = state.editableTabs
        let activeName = state.editableTabsValue
        if (activeName === targetName) {
          tabs.forEach((tab, index) =&gt; {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1]
              if (nextTab) {
                activeName = nextTab.name
              }
            }
          })
        }

        state.editableTabsValue = activeName
        state.editableTabs = tabs.filter((tab) =&gt; tab.name !== targetName)
      }
      return { ...toRefs(state), addTab, removeTab }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Tabs Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>modelValue / v-model</td>
<td>绑定值，选中选项卡的 name</td>
<td>string</td>
<td>—</td>
<td>第一个选项卡的 name</td>
</tr>
<tr>
<td>type</td>
<td>风格类型</td>
<td>string</td>
<td>card/border-card</td>
<td>—</td>
</tr>
<tr>
<td>closable</td>
<td>标签是否可关闭</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>addable</td>
<td>标签是否可增加</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>editable</td>
<td>标签是否同时可增加和关闭</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>tab-position</td>
<td>选项卡所在位置</td>
<td>string</td>
<td>top/right/bottom/left</td>
<td>top</td>
</tr>
<tr>
<td>stretch</td>
<td>标签的宽度是否自撑开</td>
<td>boolean</td>
<td>-</td>
<td>false</td>
</tr>
<tr>
<td>before-leave</td>
<td>切换标签之前的钩子，若返回 false 或者返回 Promise 且被 reject，则阻止切换。</td>
<td>Function(activeName, oldActiveName)</td>
<td>—</td>
<td>—</td>
</tr>
</tbody>
</table>
<h3>Tabs Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>tab-click</td>
<td>tab 被选中时触发</td>
<td>被选中的标签 tab 实例</td>
</tr>
<tr>
<td>tab-remove</td>
<td>点击 tab 移除按钮后触发</td>
<td>被删除的标签的 name</td>
</tr>
<tr>
<td>tab-add</td>
<td>点击 tabs 的新增按钮后触发</td>
<td>—</td>
</tr>
<tr>
<td>edit</td>
<td>点击 tabs 的新增按钮或 tab 被关闭后触发</td>
<td>(targetName, action)</td>
</tr>
</tbody>
</table>
<h3>Tab-pane Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>label</td>
<td>选项卡标题</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>name</td>
<td>与选项卡绑定值 value 对应的标识符，表示选项卡别名</td>
<td>string</td>
<td>—</td>
<td>该选项卡在选项卡列表中的顺序值，如第一个选项卡则为'1'</td>
</tr>
<tr>
<td>closable</td>
<td>标签是否可关闭</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>lazy</td>
<td>标签是否延迟渲染</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createBlock(_component_el_tabs, {
    modelValue: _ctx.activeName,
    "onUpdate:modelValue": $event => (_ctx.activeName = $event),
    onTabClick: _ctx.handleClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, {
        label: "用户管理",
        name: "first"
      }, {
        default: _withCtx(() => [
          _createTextVNode("用户管理")
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, {
        label: "配置管理",
        name: "second"
      }, {
        default: _withCtx(() => [
          _createTextVNode("配置管理")
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, {
        label: "角色管理",
        name: "third"
      }, {
        default: _withCtx(() => [
          _createTextVNode("角色管理")
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, {
        label: "定时任务补偿",
        name: "fourth"
      }, {
        default: _withCtx(() => [
          _createTextVNode("定时任务补偿")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue", "onTabClick"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const activeName = ref('second')
      const handleClick = (tab, event) => {
        console.log(tab, event)
      }
      return { activeName, handleClick }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createBlock(_component_el_tabs, {
    modelValue: _ctx.activeName,
    "onUpdate:modelValue": $event => (_ctx.activeName = $event),
    type: "card",
    onTabClick: _ctx.handleClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, {
        label: "用户管理",
        name: "first"
      }, {
        default: _withCtx(() => [
          _createTextVNode("用户管理")
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, {
        label: "配置管理",
        name: "second"
      }, {
        default: _withCtx(() => [
          _createTextVNode("配置管理")
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, {
        label: "角色管理",
        name: "third"
      }, {
        default: _withCtx(() => [
          _createTextVNode("角色管理")
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, {
        label: "定时任务补偿",
        name: "fourth"
      }, {
        default: _withCtx(() => [
          _createTextVNode("定时任务补偿")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue", "onTabClick"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const activeName = ref('first')
      const handleClick = (tab, event) => {
        console.log(tab, event)
      }
      return { activeName, handleClick }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createBlock(_component_el_tabs, { type: "border-card" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, { label: "用户管理" }, {
        default: _withCtx(() => [
          _createTextVNode("用户管理")
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { label: "配置管理" }, {
        default: _withCtx(() => [
          _createTextVNode("配置管理")
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { label: "角色管理" }, {
        default: _withCtx(() => [
          _createTextVNode("角色管理")
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { label: "定时任务补偿" }, {
        default: _withCtx(() => [
          _createTextVNode("定时任务补偿")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_radio_button = _resolveComponent("el-radio-button")
  const _component_el_radio_group = _resolveComponent("el-radio-group")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_radio_group, {
      modelValue: _ctx.tabPosition,
      "onUpdate:modelValue": $event => (_ctx.tabPosition = $event),
      style: {"margin-bottom":"30px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_radio_button, { label: "top" }, {
          default: _withCtx(() => [
            _createTextVNode("top")
          ]),
          _: 1
        }),
        _createVNode(_component_el_radio_button, { label: "right" }, {
          default: _withCtx(() => [
            _createTextVNode("right")
          ]),
          _: 1
        }),
        _createVNode(_component_el_radio_button, { label: "bottom" }, {
          default: _withCtx(() => [
            _createTextVNode("bottom")
          ]),
          _: 1
        }),
        _createVNode(_component_el_radio_button, { label: "left" }, {
          default: _withCtx(() => [
            _createTextVNode("left")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_el_tabs, {
      "tab-position": _ctx.tabPosition,
      style: {"height":"200px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, { label: "用户管理" }, {
          default: _withCtx(() => [
            _createTextVNode("用户管理")
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, { label: "配置管理" }, {
          default: _withCtx(() => [
            _createTextVNode("配置管理")
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, { label: "角色管理" }, {
          default: _withCtx(() => [
            _createTextVNode("角色管理")
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, { label: "定时任务补偿" }, {
          default: _withCtx(() => [
            _createTextVNode("定时任务补偿")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["tab-position"])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const tabPosition = ref('left')
      return { tabPosition }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { createVNode: _createVNode, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createBlock(_component_el_tabs, { type: "border-card" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, null, {
        label: _withCtx(() => [
          _createVNode("i", { class: "el-icon-date" }),
          _createTextVNode(" 我的行程")
        ]),
        default: _withCtx(() => [
          _createTextVNode(" 我的行程 ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { label: "消息中心" }, {
        default: _withCtx(() => [
          _createTextVNode("消息中心")
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { label: "角色管理" }, {
        default: _withCtx(() => [
          _createTextVNode("角色管理")
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { label: "定时任务补偿" }, {
        default: _withCtx(() => [
          _createTextVNode("定时任务补偿")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo5": (function() {
    
    const { renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode } = Vue

function render (_ctx, _cache) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createBlock(_component_el_tabs, {
    modelValue: _ctx.editableTabsValue,
    "onUpdate:modelValue": $event => (_ctx.editableTabsValue = $event),
    type: "card",
    editable: "",
    onEdit: _ctx.handleTabsEdit
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.editableTabs, (item, index) => {
        return (_openBlock(), _createBlock(_component_el_tab_pane, {
          key: item.name,
          label: item.title,
          name: item.name
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.content), 1)
          ]),
          _: 2
        }, 1032, ["label", "name"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue", "onEdit"]))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      const state = reactive({
        editableTabsValue: '2',
        editableTabs: [
          {
            title: 'Tab 1',
            name: '1',
            content: 'Tab 1 content'
          },
          {
            title: 'Tab 2',
            name: '2',
            content: 'Tab 2 content'
          }
        ],
        tabIndex: 2
      })
      const handleTabsEdit = (targetName, action) => {
        if (action === 'add') {
          let newTabName = ++state.tabIndex + ''
          state.editableTabs.push({
            title: 'New Tab',
            name: newTabName,
            content: 'New Tab content'
          })
          state.editableTabsValue = newTabName
        }
        if (action === 'remove') {
          let tabs = state.editableTabs
          let activeName = state.editableTabsValue
          if (activeName === targetName) {
            tabs.forEach((tab, index) => {
              if (tab.name === targetName) {
                let nextTab = tabs[index + 1] || tabs[index - 1]
                if (nextTab) {
                  activeName = nextTab.name
                }
              }
            })
          }

          state.editableTabsValue = activeName
          state.editableTabs = tabs.filter((tab) => tab.name !== targetName)
        }
      }

      return { ...toRefs(state), handleTabsEdit }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo6": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", { style: {"margin-bottom":"20px"} }, [
      _createVNode(_component_el_button, {
        size: "small",
        onClick: $event => (_ctx.addTab(_ctx.editableTabsValue))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" add tab ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.editableTabsValue,
      "onUpdate:modelValue": $event => (_ctx.editableTabsValue = $event),
      type: "card",
      closable: "",
      onTabRemove: _ctx.removeTab
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.editableTabs, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: item.name,
            label: item.title,
            name: item.name
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.content), 1)
            ]),
            _: 2
          }, 1032, ["label", "name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onUpdate:modelValue", "onTabRemove"])
  ], 64))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      const state = reactive({
        editableTabsValue: '2',
        editableTabs: [
          {
            title: 'Tab 1',
            name: '1',
            content: 'Tab 1 content'
          },
          {
            title: 'Tab 2',
            name: '2',
            content: 'Tab 2 content'
          }
        ],
        tabIndex: 2
      })

      const addTab = (targetName) => {
        let newTabName = ++state.tabIndex + ''
        state.editableTabs.push({
          title: 'New Tab',
          name: newTabName,
          content: 'New Tab content'
        })
        state.editableTabsValue = newTabName
      }

      const removeTab = (targetName) => {
        let tabs = state.editableTabs
        let activeName = state.editableTabsValue
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1]
              if (nextTab) {
                activeName = nextTab.name
              }
            }
          })
        }

        state.editableTabsValue = activeName
        state.editableTabs = tabs.filter((tab) => tab.name !== targetName)
      }
      return { ...toRefs(state), addTab, removeTab }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  